<!--
 * @Description: 邮寄地址
 * @Author: HuangCheng
 * @Date: 2020-03-18 19:16:16
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-22 10:50:34
 -->
<template>
  <!-- <a-card :bordered="false"> -->
    <div>
    <!-- <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :lg="6" :md="12" :sm="24">
            <a-form-item label="联系人姓名">
              <a-input v-model="searchForm.contactsName" placeholder="请输入联系人姓名"/>
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-button type="primary" icon="search" @click="handleToSearchContract">查询</a-button>
            <a-button style="margin-left: 8px" icon="reload" @click="resetSearchAccount">重置</a-button>
            <a-button type="primary" style="margin-left: 8px" @click="add" v-if="emailList.length<1">添加</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div> -->

    <div class="add_btn">
      <span></span>
      <span class="add_text" @click="add" v-if="emailList.length<1">添加地址</span>
    </div>

    <s-table
      ref="contractTable"
      size="default"
      :rowKey="(record) => record.id"
      :columns="tableColumns"
      :data="loadAccountList"
      bordered
    >
      <span slot="action" slot-scope="text, record">
        <a @click="edit(record)">编辑</a>
      </span>
    </s-table>

    <a-modal :title="title"
     width="800px" :visible="contractCreateModal" :confirmLoading="confirmContract" @ok="handleCreateContractModalOk" @cancel="handleCreateContractModalCancel">
      <a-form :form="createForm" :hideRequiredMark="true">
        <a-row class="form-row" :gutter="16">
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="联系人姓名" :labelCol="{lg: {span: 6}, sm: {span: 7}}" :wrapperCol="{lg: {span: 18}, sm: {span: 17} }">
              <a-input placeholder="请输入联系人姓名"
                v-decorator="['contactsName', validatorRules.contractName]" />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="联系人电话" :labelCol="{lg: {span: 6}, sm: {span: 7}}" :wrapperCol="{lg: {span: 18}, sm: {span: 17} }">
              <a-input placeholder="请输入联系人电话"
                v-decorator="['contactsPhone', validatorRules.contractPhone]" />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="联系人地址" :labelCol="{lg: {span: 6}, sm: {span: 7}}" :wrapperCol="{lg: {span: 18}, sm: {span: 17} }">
              <a-input placeholder="请输入联系人地址"
                v-decorator="['contactsAddress', validatorRules.contractAddr]" />
            </a-form-item>
          </a-col>
          
          <!-- <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="是否默认" :labelCol="{lg: {span: 6}, sm: {span: 7}}" :wrapperCol="{lg: {span: 18}, sm: {span: 17} }">
              <a-select style="width: 100%" placeholder="请选择"
                v-decorator="['isDefault', validatorRules.contractType]">
                <a-select-option value="10">默认</a-select-option>
                <a-select-option value="20">非默认</a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
        </a-row>
      </a-form>
    </a-modal>
  <!-- </a-card> -->
  </div>
</template>
<script>
  import STable from '@/components/table/';
  import AInput from "ant-design-vue/es/input/Input";
  import {getArrItem} from '@/utils/util.js';
  import Vue from 'vue'
  import { ACCESS_TOKEN } from "@/store/mutation-types"
  import moment from 'moment'
  import {
    getEmailList,
    updateEnterpriseContractStatus,
    addEmailList,
    editEmailList
  } from '@/api/api';

  export default {
    name: "ContractInfo",
    components: {
      AInput,
      STable,
    },
    data () {
      return {
        uploadData: {
          fileType: '103201'
        },
        searchForm: {
          contactsName: undefined,
        },
        headers: {'X-Access-Token': Vue.ls.get(ACCESS_TOKEN)},
        url: {
          frontUrl: window._CONFIG['domianURL'] + '/api/tax/common/file/upload',
        },
        contractType: [{
          value: '103201',
          label: '企业与平台合同'
        }, {
          value: '103202',
          label: '企业与C端业务合同'
        }],
        tableColumns: [
          {
            title: '联系人姓名',
            align: 'center',
            dataIndex: 'contactsName',
          },
          {
            title: '联系人地址',
            align: 'center',
            dataIndex: 'contactsAddress',
          },
          {
            title: '联系人电话',
            align: 'center',
            dataIndex: 'contactsPhone',
          },
          
          {
            title: '状态',
            align: 'center',
            dataIndex: 'status',
            customRender: text => text==='100201'?'正常':'停用'
          },
          {
            title: '操作',
            align: 'center',
            dataIndex: 'action',
            width: '120px',
            scopedSlots: { customRender: 'action' },
          }
        ],
        contractCreateModal: false,
        confirmContract: false,
        createForm: this.$form.createForm(this),
        validatorRules: {
          contractName: {
            rules: [{
              required: true, message: '请输入联系人姓名'
            }]
          },
          contractAddr: {
            rules: [{
              required: true, message: '请输入联系人地址'
            }]
          },
          contractPhone: {
            rules: [{
              required: true, message: '请输入联系人电话'
            }]
          },
          contractType: {
            rules: [{
              required: true, message: '请选择类型'
            }]
          },
        },
        extraAgreement: '',
        allowAdd: false,
        fileList: [],
        title: '',
        record: '',
        emailList: []
      }
    },
    props: {
      enterpriseId: {
        type: String,
        default: '',
        required: false
      },
    },
    methods: {
      // 新增
      add () {
        this.contractCreateModal = true
        this.title = '新增'
      },
      changeStartDate(date, dateString) {
        this.createForm.setFieldsValue({
          contractSignDate: dateString,
        });
      },
      changeEndDate(date, dateString) {
        // this.createForm.setFieldsValue({
        //   contractValidity: dateString,
        // });
      },
      // 编辑
      edit (record) {
        this.contractCreateModal = true
        this.title = '编辑'
        this.record = record
        setTimeout(()=>{
          this.createForm.setFieldsValue({
            contactsAddress: record.contactsAddress,
            contactsName: record.contactsName,
            contactsPhone: record.contactsPhone,
            isDefault: record.isDefault,
          });
        },500)
      },
      // 启用合同
      begin (record) {
        let that = this
        this.$confirm({
          title: '确定启用？',
          onOk() {
            return new Promise((resolve, reject) => {
              updateEnterpriseContractStatus({id: record.id,status: '100201'}).then((res) => {
                if (res.success) {
                  that.$message.success(res.message);
                  that.handleToSearchContract();
                  resolve();
                } else {
                  that.$message.error(res.message);
                  reject();
                }
              });
            }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {
            // console.log('Cancel');
          },
        });
      },
      /**
       * @description: 加载列表数据
       * @param : parameter 查询参数
       * @return: promise
       * @Author: HuangCheng
       */
      loadAccountList(parameter) {
        return getEmailList(Object.assign(parameter, this.searchForm, {
          enterpriseId: this.enterpriseId
        })).then(res => {
          this.emailList = res.result.data
          return res.result
        })
      },
      /**
       * @description: 查看详情
       * @param : record
       * @return: void
       * @Author: HuangCheng
       */
      checkDetail(record) {
        // this.currentId = record.id;
        // this.visibleDetailModal = true;
        window.open(record.contractEnclosure, '_blank');
        // console.log(record)
      },
      /**
       * @description: 查询用户、账户列表
       * @Author: HuangCheng
       */
      handleToSearchContract() {
        this.$refs.contractTable.refresh(true);
      },
      /**
       * @description: 重置搜索条件
       * @param : 
       * @return: 
       * @Author: HuangCheng
       */
      resetSearchAccount() {
        this.searchForm = {
          contractName: undefined
        };
        this.handleToSearchContract();
      },
      /**
       * @description: 添加合同
       * @return: void
       * @Author: HuangCheng
       */  
      handleCreateContractModalOk() {
        this.createForm.validateFields((err, fieldsValue) => {
          if (err) {
            return;
          }
          const description = this.createForm.getFieldsValue();
          this.confirmLoading = true;
          if (this.title === '新增') {
            addEmailList(Object.assign(description, {
              enterpriseId: this.enterpriseId,isDefault:10
            })).then((res) => {
              this.confirmLoading = false;
              if (res.success) {
                this.$message.success(res.message);
                this.handleCreateContractModalCancel();
                this.handleToSearchContract();
                this.contractCreateModal = false;
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            editEmailList(Object.assign(description, {
              enterpriseId: this.enterpriseId,
              id: this.record.id
            })).then((res) => {
              this.confirmLoading = false;
              if (res.success) {
                this.$message.success(res.message);
                this.handleCreateContractModalCancel();
                this.handleToSearchContract();
                this.contractCreateModal = false;
              } else {
                this.$message.error(res.message);
              }
            });
          }
        })
      },
      /**
       * @description: 关闭弹窗--添加邮寄
       * @return: void
       * @Author: HuangCheng
       */  
      handleCreateContractModalCancel() {
        this.createForm.resetFields();
        this.contractCreateModal = false;
      },
      // 上传邮寄附件
      handleChangeUpLoadFile(info) {
        this.extraAgreement = ''
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        this.allowAdd = true
        fileList = fileList.map(file => {
          if (file.response&&file.response.code!==500) {
            file.url = 'https://oss.zfycloud.com' + "/" + file.response.result
            file.fileName = file.name
            file.fileUrl = file.url
          } else if (file.response&&file.response.code===500) {
            this.$message.error(file.response.message)
            file.status = 'error'
            this.allowAdd = false
          }
          return file;
        });
        this.fileList = fileList;
        // if (info.file.status !== 'uploading') {
        // }
        // if (info.file.status === 'done') {
        //   let formdata = new FormData();
        //   formdata.append('file', info.fileList[info.fileList.length-1].originFileObj);
        //   formdata.append('fileType', '103201')
        //   uploadFile(formdata).then(res => {
        //     if (res.success) {
        //       this.extraAgreement = 'https://oss.zfycloud.com' + "/" + res.result
        //       this.$message.success(`${info.file.name} 上传成功`);
        //       this.allowAdd = true
        //     } else {
        //       this.$message.error(`上传失败`);
        //       this.allowAdd = false
        //     }
        //   })
        // }
      },
      beforeUpload(file) {
        const isJpgOrPng = file.type === 'application/pdf';
        if (!isJpgOrPng) {
          this.$message.error('文件类型只能为pdf格式!');
          this.allowAdd = false
        }
        return isJpgOrPng;
      },
    },
  }
</script>
<style lang="less">
.add_btn{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .add_text{
    cursor: pointer;
    color: #347CF5;
    font-size: 14px;
  }
}
.detail-modal {
  top: 0;
  // height: 100vh;
  .ant-modal-content {
    // height: 100vh;
    .ant-modal-body {
      // height: calc(100% - 55px);
      padding: 0;
    }
  }
}
</style>