<!--
 * @Description: 用户/账户管理--提现
 * @Author: HuangCheng
 * @Date: 2020-03-18 19:16:16
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-22 09:42:22
 -->
<template>
  <!-- <a-card :bordered="false"> -->
    <div>
    <div class="edit_section">
      <commonTitle title="合同信息" />
      <div class="edit_btn">
        <!-- <span class="edit" @click="contractCreateModal = true">添加合同</span> -->
        <span class="edit" @click="addContrast">添加合同</span>
        <!-- <a-button type="primary">保存</a-button> -->
      </div>
    </div>
    
    <div class="info_box">
    <s-table
      ref="contractTable"
      size="default"
      :rowKey="(record) => record.id"
      :columns="tableColumns"
      :data="loadAccountList"
      bordered
    >
      <span slot="action" slot-scope="text, record">
        <!-- <a @click="checkDetail(record)">预览</a> -->
        <a :href="record.contractEnclosure" target="_blank">预览</a>
        <!-- <a-divider type="vertical" />
        <a @click="stop(record)" v-if="record.status === '100201'">停用</a>
        <a @click="begin(record)" v-else>启用</a> -->
      </span>
    </s-table>
    </div>
    <a-modal
      title="添加合同"
      width="800px"
      :visible="contractCreateModal"
      :confirmLoading="confirmContract"
      @ok="handleCreateContractModalOk"
      @cancel="handleCreateContractModalCancel"
    >
      <a-form :form="createForm" :hideRequiredMark="true">
        <a-row class="form-row" :gutter="16">
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="合同编号"
              :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
            >
              <a-input
                placeholder="请输入合同编号"
                v-decorator="['contractNo', validatorRules.contractNo]"
              />
            </a-form-item>
          </a-col>
          <!-- <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="合同名称"
              :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
            >
              <a-input
                placeholder="请输入合同名称"
                v-decorator="['contractName', validatorRules.contractName]"
              />
            </a-form-item>
          </a-col> -->
          <!-- <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="合同类型"
              :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
            >
              <a-select
                style="width: 100%"
                placeholder="请选择合同类型"
                v-decorator="['contractType', validatorRules.contractType]"
              >
                <a-select-option
                  v-for="item in contractType"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col> -->
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="开始日期"
              :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
            >
              <a-date-picker
                format="YYYY-MM-DD"
                style="width: 100%"
                v-decorator="[
                  'contractSignDate',
                  validatorRules.contractSignDate,
                ]"
                @change="changeStartDate"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="到期时间"
              :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
              @change="changeEndDate"
            >
              <a-date-picker
                format="YYYY-MM-DD"
                style="width: 100%"
                v-decorator="[
                  'contractValidity',
                  validatorRules.contractValidity,
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="合同附件"
              :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
            >
              <a-upload
                ref="upload"
                name="file"
                :multiple="false"
                :action="url.frontUrl"
                :headers="headers"
                :file-list="fileList"
                v-decorator="[
                  'extraAgreements',
                  validatorRules.extraAgreements,
                ]"
                @change="handleChangeUpLoadFile"
                :data="uploadData"
                :beforeUpload="beforeUpload"
                accept=".pdf"
              >
                <a-button> <a-icon type="upload" /> 上传pdf文件 </a-button>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  <!-- </a-card> -->
  </div>
</template>
<script>
import STable from "@/components/table/";
import AInput from "ant-design-vue/es/input/Input";
import { getArrItem } from "@/utils/util.js";
import Vue from "vue";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import moment from "moment";
import commonTitle from "../../components/commonTitle";
import {
  getEnterpriseContractList,
  addEnterpriseContract,
  updateEnterpriseContractStatus,
} from "@/api/api";

export default {
  name: "ContractInfo",
  components: {
    AInput,
    STable,
    commonTitle,
  },
  data() {
    return {
      uploadData: {
        fileType: "103201",
      },
      searchForm: {
        contractNo: "",
        contractName: "",
      },
      headers: { "X-Access-Token": Vue.ls.get(ACCESS_TOKEN) },
      url: {
        frontUrl: window._CONFIG["domianURL"] + "/api/tax/common/file/upload",
      },
      contractType: [
        {
          value: "103201",
          label: "企业与平台合同",
        },
        
      ],
      tableColumns: [
        {
          title: "编号",
          align: "center",
          dataIndex: "contractNo",
        },
        {
          title: "名称",
          align: "center",
          dataIndex: "contractName",
        },
        
        {
          title: "开始日期",
          align: "center",
          dataIndex: "contractSignDate",
        },
        {
          title: "到期时间",
          align: "center",
          dataIndex: "contractValidity",
        },
        {
          title: "录入时间",
          align: "center",
          dataIndex: "createTime",
        },
        {
          title: "状态",
          align: "center",
          dataIndex: "status",
          customRender: (text) => (text === "100201" ? "执行中" : "已停用"),
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "action",
          width: "120px",
          scopedSlots: { customRender: "action" },
        },
      ],
      contractCreateModal: false,
      confirmContract: false,
      createForm: this.$form.createForm(this),
      validatorRules: {
        contractNo: {
          rules: [
            {
              required: true,
              message: "请输入合同编码",
            },
          ],
        },
        extraAgreements: {
          rules: [
            {
              required: true,
              message: "请上传合同附件",
            },
          ],
        },
        contractName: {
          rules: [
            {
              required: true,
              message: "请输入合同名称",
            },
          ],
        },
        contractType: {
          rules: [
            {
              required: true,
              message: "请选择合同类型",
            },
          ],
        },
        contractSignDate: {
          rules: [
            {
              required: true,
              message: "请选择开始时间",
            },
          ],
        },
        contractValidity: {
          rules: [
            {
              required: true,
              message: "请选择过期时间",
            },
          ],
        },
      },
      extraAgreement: "",
      allowAdd: false,
      fileList: [],
    };
  },
  props: {
    enterpriseId: {
      type: String,
      default: "",
      required: false,
    },
  },
  methods: {
    addContrast(){
      console.log(666);
      this.contractCreateModal = true;
      console.log(this.contractCreateModal);
    },
    changeStartDate(date, dateString) {
      this.createForm.setFieldsValue({
        contractSignDate: dateString,
      });
    },
    changeEndDate(date, dateString) {
      // this.createForm.setFieldsValue({
      //   contractValidity: dateString,
      // });
    },
    // 停用合同
    stop(record) {
      let that = this;
      this.$confirm({
        title: "确定停用？",
        onOk() {
          return new Promise((resolve, reject) => {
            updateEnterpriseContractStatus({
              id: record.id,
              status: "100202",
            }).then((res) => {
              if (res.success) {
                that.$message.success(res.message);
                that.handleToSearchContract();
                resolve();
              } else {
                that.$message.error(res.message);
                reject();
              }
            });
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    },
    // 启用合同
    begin(record) {
      let that = this;
      this.$confirm({
        title: "确定启用？",
        onOk() {
          return new Promise((resolve, reject) => {
            updateEnterpriseContractStatus({
              id: record.id,
              status: "100201",
            }).then((res) => {
              if (res.success) {
                that.$message.success(res.message);
                that.handleToSearchContract();
                resolve();
              } else {
                that.$message.error(res.message);
                reject();
              }
            });
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    },
    /**
     * @description: 加载列表数据
     * @param : parameter 查询参数
     * @return: promise
     * @Author: HuangCheng
     */
    loadAccountList(parameter) {
      return getEnterpriseContractList(
        Object.assign(parameter, this.searchForm, {
          enterpriseId: this.enterpriseId,
        })
      ).then((res) => {
        return res.result;
      });
    },
    /**
     * @description: 查看详情
     * @param : record
     * @return: void
     * @Author: HuangCheng
     */
    checkDetail(record) {
      // this.currentId = record.id;
      // this.visibleDetailModal = true;
      window.open(record.contractEnclosure, "_blank");
      // console.log(record)
    },
    /**
     * @description: 查询用户、账户列表
     * @Author: HuangCheng
     */
    handleToSearchContract() {
      this.$refs.contractTable.refresh(true);
    },
    /**
     * @description: 重置搜索条件
     * @param :
     * @return:
     * @Author: HuangCheng
     */
    resetSearchAccount() {
      this.searchForm = {
        contractNo: "",
        contractName: "",
      };
      this.handleToSearchContract();
    },
    /**
     * @description: 添加合同
     * @return: void
     * @Author: HuangCheng
     */
    handleCreateContractModalOk() {
      this.createForm.validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }
        const description = this.createForm.getFieldsValue();
        this.confirmLoading = true;
        if (
          this.fileList.length > 0 &&
          this.allowAdd === true &&
          this.fileList[0].fileUrl !== ""
        ) {
          addEnterpriseContract(
            Object.assign(
              {},
              {
                contractName: description.contractName,
                contractNo: description.contractNo,
                contractSignDate: moment(description.contractSignDate).format(
                  "YYYY-MM-DD"
                ),
                contractType: description.contractType,
                contractValidity: moment(description.contractValidity).format(
                  "YYYY-MM-DD"
                ),
                enterpriseId: this.enterpriseId,
                contractEnclosure: this.fileList[0].fileUrl,
                contractName:'共享经济合作协议',
                contractType:'103201',
              }
            )
          ).then((res) => {
            this.confirmLoading = false;
            if (res.success) {
              this.$message.success("添加成功");
              this.handleCreateContractModalCancel();
              this.handleToSearchContract();
              this.createForm.resetFields();
              this.contractCreateModal = false;
              this.extraAgreement = "";
              this.allowAdd = false;
            } else {
              this.$message.error(res.message);
            }
          });
        } else if (this.fileList.length === 0) {
          this.$message.error("合同附件不能为空");
        } else if (this.fileList.length > 0 && !this.allowAdd) {
          this.$message.error("文件上传出错，请重新上传");
        } else if (this.fileList[0].fileUrl === "") {
          this.$message.warning("文件正在上传中");
        }
      });
    },
    /**
     * @description: 关闭弹窗--添加合同
     * @return: void
     * @Author: HuangCheng
     */
    handleCreateContractModalCancel() {
      this.createForm.resetFields();
      this.contractCreateModal = false;
      this.extraAgreement = "";
      this.allowAdd = false;
      const mainImg = this.$refs.upload;
      mainImg.sFileList = [];
    },
    // 上传合同附件
    handleChangeUpLoadFile(info) {
      this.extraAgreement = "";
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      this.allowAdd = true;
      fileList = fileList.map((file) => {
        if (file.response && file.response.code !== 500) {
          file.url = "https://oss.zfycloud.com" + "/" + file.response.result;
          file.fileName = file.name;
          file.fileUrl = file.url;
        } else if (file.response && file.response.code === 500) {
          this.$message.error(file.response.message);
          file.status = "error";
          this.allowAdd = false;
        }
        return file;
      });
      this.fileList = fileList;
      // if (info.file.status !== 'uploading') {
      // }
      // if (info.file.status === 'done') {
      //   let formdata = new FormData();
      //   formdata.append('file', info.fileList[info.fileList.length-1].originFileObj);
      //   formdata.append('fileType', '103201')
      //   uploadFile(formdata).then(res => {
      //     if (res.success) {
      //       this.extraAgreement = 'https://oss.zfycloud.com' + "/" + res.result
      //       this.$message.success(`${info.file.name} 上传成功`);
      //       this.allowAdd = true
      //     } else {
      //       this.$message.error(`上传失败`);
      //       this.allowAdd = false
      //     }
      //   })
      // }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === "application/pdf";
      if (!isJpgOrPng) {
        this.$message.error("文件类型只能为pdf格式!");
        this.allowAdd = false;
      }
      return isJpgOrPng;
    },
  },
};
</script>
<style lang="less">
.edit_section {
  display: flex;
  justify-content: space-between;
  .edit_btn {
    .edit {
      color: #347cf5;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
.info_box{
    padding-left: 18px;
    margin-top: 17px;
  }
.detail-modal {
  top: 0;
  // height: 100vh;
  .ant-modal-content {
    // height: 100vh;
    .ant-modal-body {
      // height: calc(100% - 55px);
      padding: 0;
    }
  }
}
</style>