<!--
 * @Descripttion: 
 * @version: 
 * @Author: xyh
 * @Date: 2021-04-13 10:29:31
 * @LastEditTime: 2021-04-23 15:01:31
-->
<template>
  <div class="detail_config">
    <!-- 面包屑 -->
    <div class="bread">
      <span class="txt_e" @click="toEnterpriseManage">企业管理</span>
      <a-icon type="right" :style="{ color: '#DEDEDE' }" />
      <span class="txt_d">详情</span>
    </div>
    <!-- 基本信息 -->
    <div class="basic_info">
      
      <!-- 编辑信息 -->
      <a-modal
        title="编辑"
        width="1000px"
        :visible="visibleCreateModal"
        :confirmLoading="editBaseInfoconfirmLoading"
        @ok="handleCreateModalOk"
        @cancel="handleCreateModalCancel"
      >
        <a-form :form="editBaseForm" :hideRequiredMark="true">
          <a-row class="form-row" :gutter="16">
            <a-col :lg="24" :md="24" :sm="24">
              <p style="color: #333333; font-size: 16px">企业基本信息</p>
            </a-col>
          </a-row>
          <a-row class="form-row" :gutter="16">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item
                label="企业名称"
                :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
                :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
              >
                <a-input
                  placeholder="请输入企业名称"
                  v-decorator="[
                    'enterpriseName',
                    validatorRules.enterpriseName,
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item
                label="行业类型"
                :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
                :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
              >
                <a-select
                  class="w-100"
                  style="width:200px"
                  placeholder="请选择行业类型"
                  showSearch
                  :defaultActiveFirstOption="false"
                  :filterOption="false"
                  @search="handleSearchSelectCompany"
                  @change="handleChangeSelectCompany"
                  v-decorator="[
                    'enterpriseIndustry',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请选择行业类型',
                          whitespace: true,
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    v-for="(item, index) in industryLists"
                    :value="item.id"
                    :key="index"
                    >{{ item.industryName }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row class="form-row" :gutter="16">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item
                label="省市区"
                :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
                :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
              >
                <a-cascader
                  placeholder="请选择省市区"
                  v-decorator="[
                    'residence',
                    {
                      rules: [
                        {
                          type: 'array',
                          required: true,
                          message: '请选择省市区',
                        },
                      ],
                    },
                  ]"
                  :options="residences"
                  @change="selectArea"
                  :show-search="{ filter }"
                />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item
                label="详细地址"
                :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
                :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
              >
                <a-input
                  placeholder="请输入详细地址(不包含省市区)"
                  v-decorator="[
                    'enterpriseAddress',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请输入详细地址(不包含省市区)',
                          whitespace: true,
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row class="form-row" :gutter="16">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item
                label="联系人"
                :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
                :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
              >
                <a-input
                  placeholder="请输入企业联系人"
                  v-decorator="[
                    'enterpriseContacts',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请输入企业联系人',
                          whitespace: true,
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item
                label="联系电话"
                :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
                :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
              >
                <a-input
                  placeholder="请输入企业联系电话"
                  v-decorator="[
                    'enterprisePhone',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请输入企业联系电话',
                          whitespace: true,
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row class="form-row" :gutter="16">
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item
                label="纳税人类型"
                :labelCol="{ lg: { span: 6 }, sm: { span: 7 } }"
                :wrapperCol="{ lg: { span: 18 }, sm: { span: 17 } }"
              >
                <a-select
                  class="w-100"
                  placeholder="请选择纳税人类型"
                  v-decorator="[
                    'taxpayerType',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请选择纳税人类型',
                          whitespace: true,
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    v-for="item in taxpayerType"
                    :value="item.value"
                    :key="item.value"
                    >{{ item.label }}</a-select-option
                  >
                </a-select>
              </a-form-item>
            </a-col>
            
          </a-row>
         
        </a-form>
      </a-modal>

      <div class="edit_section">
        <commonTitle title="基本信息" />
        <div class="edit_btn">
          <span class="edit" @click="handleEdit">编辑</span>
          <!-- <a-button type="primary">保存</a-button> -->
          
          

        </div>
      </div>
      <div class="info_box">
        <div class="row mar_bott_18">
          <div class="col">
            <span class="type">企业编号：</span
            ><span class="desc">{{ detailInfo.id }}</span>
          </div>
           <div class="col">
            <span class="type">企业名称：</span
            ><span class="desc">{{ detailInfo.enterpriseName }}</span>
          </div>
          <div class="col">
            <span class="type">行业类型：</span
            ><span class="desc">{{detailInfo.enterpriseIndustryText}}</span>
          </div>
          
        </div>
        <div class="row mar_bott_18">
         <div class="col">
            <span class="type">联系人：</span
            ><span class="desc">{{ detailInfo.enterpriseContacts }}</span>
          </div>
          <div class="col">
            <span class="type">联系电话：</span
            ><span class="desc">{{ detailInfo.enterprisePhone }}</span>
          </div>
           <div class="col">
            <span class="type">地址：</span
            ><span class="desc">{{detailInfo.detailedAddress}}{{detailInfo.enterpriseAddress}}</span>
          </div>
          
        </div>
        <div class="row mar_bott_18">
          
          
         <div class="col">
            <span class="type">纳税人类型：</span
            ><span class="desc">{{
              taxpayerTypeText[detailInfo.taxpayerType]
            }}</span>
          </div>
          
          <div class="col">
            <span class="type">企业状态：</span
            ><span class="desc">{{
              detailInfo.status === "101101"
                ? "未审核"
                : detailInfo.status === "101102"
                ? "停用"
                : detailInfo.status === "101111"
                ? "审核成功"
                : ""
            }}</span>
          </div>
          <div class="col">
            <span class="type">创建时间：</span
            ><span class="desc">{{detailInfo.createTime}}</span>
          </div>
        </div>
      </div>
      
    </div>

    <!-- 合同信息 -->
    <div class="contract_info">
      <ContractInfo :enterpriseId="enterpriseId" />
    </div>

    <!-- 企业认证 -->
    <div class="enterprise_auth">
      <div class="edit_section">
        <commonTitle title="企业认证" />
        <div class="edit_btn">
          <span class="edit"  @click="enterpriseAuthFlag = 'save'" v-if="enterpriseAuthFlag === 'edit'">编辑</span>
          <span class="edit"  @click="handleSubmitCompanyIdentify" v-else>提交</span>
        </div>
      </div>
      <div class="info_box">
        
        


        <a-form-model
          ref="companyIdentifyRule"
          :model="companyIdentifyParams"
          :rules="companyIdentifyRules"
          layout="inline"
          :hideRequiredMark="true"
        >
          <div style="display: flex">
            <div style="width: 33.333%">
              <a-form-model-item
                label="工商注册名称"
                prop="realName"
                ref="realName"
              >
                <span v-if="enterpriseAuthFlag === 'edit'">{{companyIdentifyParams.realName}}</span>
                <a-input
                  v-else
                  v-model="companyIdentifyParams.realName"
                  @blur="
                    () => {
                      $refs.realName.onFieldBlur();
                    }
                  "
                  style="width: 200px"
                />
              </a-form-model-item>
            </div>
            <div style="width: 33.333%">
              <a-form-model-item
                label="企业信用代码"
                prop="realCode"
                ref="realCode"
              >
                <span v-if="enterpriseAuthFlag === 'edit'">{{companyIdentifyParams.realCode}}</span>
                <a-input
                  v-else
                  v-model="companyIdentifyParams.realCode"
                  @blur="
                    () => {
                      $refs.realCode.onFieldBlur();
                    }
                  "
                />
              </a-form-model-item>
            </div>
            <div style="width: 33.333%">
              
              <a-form-model-item label="过期日期" prop="remarkDate">
                <span v-if="enterpriseAuthFlag === 'edit'">{{timeValue1}}</span>
                <a-date-picker
                  v-else
                  :allowClear="false"
                  v-model="companyIdentifyParams.remarkDate"
                  :value="timeValue"
                  @change="changeTime"
                  style="width: 100%"
                />
              </a-form-model-item>
            </div>
          </div>

          <div style="margin-top: 30px; padding-right: 15px">
            <span style="color:rgba(0, 0, 0, 0.85);">资料上传：</span>
            <a-form-model-item label="" prop="enterpriseLicense" required>
              <a-upload
                name="avatar"
                listType="picture-card"
                class="avatar-uploader"
                :action="url.frontUrl"
                :headers="headers"
                :showUploadList="false"
                :beforeUpload="beforeUpload"
                @change="handleChange($event, 'enterpriseLicense')"
                v-decorator="[
                  'enterpriseLicense',
                  { rules: [{ required: true, message: '请上传营业执照!' }] },
                ]"
              >
                <img
                  class="picture-common"
                  v-if="enterpriseLicense"
                  :src="enterpriseLicense"
                  alt="avatar"
                />
                <div v-else>
                  <a-icon :type="loadingBusinessLicence ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">营业执照</div>
                </div>
              </a-upload>
            </a-form-model-item>
            <a-form-model-item label="">
              <div class="upload_box">
                <a-upload
                  name="企业现场照片"
                  listType="picture-card"
                  class="avatar-uploader"
                  :action="url.frontUrl"
                  :headers="headers"
                  :showUploadList="false"
                  :beforeUpload="beforeUpload"
                  @change="handleChange($event, 'sitePhotos1')"
                >
                  <img
                    class="picture-common"
                    v-if="sitePhotos1"
                    :src="sitePhotos1"
                    alt="avatar"
                  />
                  <div v-else>
                    <a-icon :type="loadingidCardFront ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">企业现场照片</div>
                  </div>
                </a-upload>
                <a-upload
                  name="企业现场照片"
                  listType="picture-card"
                  class="avatar-uploader"
                  :action="url.frontUrl"
                  :headers="headers"
                  :showUploadList="false"
                  :beforeUpload="beforeUpload"
                  @change="handleChange($event, 'sitePhotos2')"
                >
                  <img
                    class="picture-common"
                    v-if="sitePhotos2"
                    :src="sitePhotos2"
                    alt="avatar"
                  />
                  <div v-else>
                    <a-icon :type="loadingidCardOpsite ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">企业现场照片</div>
                  </div>
                </a-upload>
              </div>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
    </div>

    <!-- 开票范围 -->
    <div class="invoice_range">
      <div class="edit_section">
        <commonTitle title="开票范围" />
        <div class="edit_btn">
          <span class="edit" @click="openInvoiceRangeModal">添加</span>
          <!-- <a-button type="primary">保存</a-button> -->
        </div>
      </div>
      <div class="info_box">
        <s-table
          ref="table"
          size="default"
          :rowKey="(record, index) => index"
          :columns="scopeColumns"
          :data="loadScopeList"
          :pagination="pagination"
          bordered
        >
          <span
            slot="action"
            slot-scope="text, record"
            v-if="record.status === '100201'"
          >
            <a @click="freeStaff(record)">停用</a>
          </span>
          <span slot="action" slot-scope="text, record" v-else>
            <a @click="returnOk(record)">启用</a>
          </span>
        </s-table>

        <!-- 添加 -->
        <a-modal
          title="添加"
          :visible="addInvoiceRangeModal"
          @ok="handleScopeModalOk"
          @cancel="handleScopeModalCancel"
        >
          <a-form :form="addScopeForm" class="config-form" :hideRequiredMark="true">
            <a-form-item
              label="开票范围"
              :labelCol="{ lg: { span: 4 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 20 }, sm: { span: 17 } }"
            >
              <a-select
                style="width: 100%"
                placeholder="请选择开票范围"
                showSearch
                :defaultActiveFirstOption="false"
                :filterOption="false"
                @search="handleSearchSelectCompany"
                mode="multiple"
                @change="handleChangeSelectCompany"
                v-decorator="[
                  'businessScopeId',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择开票范围',
                        type: 'array',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in scopeRateList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.businessScope }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-form>
        </a-modal>
      </div>
    </div>

    <!-- 配置信息 -->
    <div class="config_info">
      <div class="edit_section">
        <commonTitle title="配置信息" />
        <div class="edit_btn">
          <span class="edit" @click="handleConfigSubmit">{{
            modalType === "detail" ? "编辑" : "提交"
          }}</span>
        </div>
      </div>
      <div class="info_box">
        <a-form :form="configForm" class="config-form" :hideRequiredMark="true" labelAlign="left">
          <div style="display: flex">
            <div style="width: 33.333%">
              <a-form-item
                
                
                
              >
              <span>企业手机号：</span>
                <span v-if="modalType === 'detail'">{{
                  configDetail.bind_phone
                }}</span>
                <a-input
                  style="width: 200px"
                  v-else
                  placeholder="请输入企业绑定手机号"
                  v-decorator="[
                    'bind_phone',
                    {
                      rules: [
                        { validator: validatePhone },
                        {
                          required: true,
                          message: '请输入企业手机号',
                          whitespace: true,
                          
                        },
                        
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </div>
            <div style="width: 33.333%">
              <a-form-item
                
                
              >
              <span>企业邮箱：</span>
                <span v-if="modalType === 'detail'">{{
                  configDetail.bind_mail
                }}</span>
                <a-input
                  style="width: 200px"
                  v-else
                  placeholder="请输入企业邮箱"
                  v-decorator="[
                    'bind_mail',
                    {
                      rules: [
                        { type: 'email', message: '邮箱格式不正确' },
                        {
                          required: true,
                          message: '请输入企业绑定邮箱',
                          whitespace: true,
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </div>
            <div style="width: 33.333%">
              <a-form-item
                
              >
              <span>单笔限额：</span>
                <span v-if="modalType === 'detail'">{{
                  configDetail.quotas_bank
                }}</span>
                <a-input
                  style="width: 200px"
                  v-else
                  placeholder="请输入单笔限额"
                  v-decorator="[
                    'quotas_bank',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请输入单笔限额',
                          whitespace: true,
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>
        </a-form>
      </div>
    </div>

    <!-- 开票信息 -->
    <div class="invoice_info">
      <div class="edit_section">
        <commonTitle title="开票信息" />
        <div class="edit_btn">
          <span
            class="edit"
            v-if="drwaInvoiceInfoFlag === 'edit'"
            @click="drwaInvoiceInfoFlag = 'save'"
            >编辑</span
          >
          <span class="edit" v-else @click="saveDrawInvoiceInfo"
            >提交</span
          >
        </div>
      </div>
      <div class="info_box">
        <div v-if="drwaInvoiceInfoFlag === 'edit'">
          <div class="row">
            <div class="col mar_bott_18">
              <span
                ><span class="type">户名：</span
                ><span class="desc">{{
                  bankInfoParams.payeeName || "--"
                }}</span></span
              >
            </div>
            <div class="col">
              <span class="type">纳税人识别号：</span
              ><span class="desc">{{
                companyIdentifyParams.realCode || "--"
              }}</span>
            </div>
            <div class="col">
              <span class="type">开户行：</span
              ><span class="desc">{{
                bankInfoParams.bankName || "--"
              }}</span>
            </div>
          </div>
          <div class="row mar_bott_18">
            <div class="col">
              <span class="type">账号：</span
              ><span class="desc">{{
                bankInfoParams.payeeBankNo || "--"
              }}</span>
            </div>
            <div class="col">
              <span class="type">地址：</span
              ><span class="desc">{{
                bankInfoParams.address || "--"
              }}</span>
            </div>
            <div class="col">
              <span class="type">电话：</span
              ><span class="desc">{{
                bankInfoParams.phone || "--"
              }}</span>
            </div>
          </div>
        </div>
        <div v-else>
          <a-form-model
            ref="bankInfoRule"
            layout="inline"
            :hideRequiredMark="true"
            :model="bankInfoParams"
            :rules="rules"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 12 }"
          >
            <div style="display: flex">
              <div style="width: 33.3333333%">
                <a-form-model-item
                  label="户名"
                  prop="payeeName"
                  ref="payeeName"
                >
                  <a-input
                    disabled
                    v-model="bankInfoParams.payeeName"
                    @blur="
                      () => {
                        $refs.payeeName.onFieldBlur();
                      }
                    "
                    read-only
                    style="width: 200px"
                  />
                </a-form-model-item>
              </div>
              <div style="width: 33.3333333%">
                <a-form-model-item
                  label="纳税人识别号"
                  prop="realCode"
                  ref="realCode"
                >
                  <a-input
                    disabled
                    v-model="companyIdentifyParams.realCode"
                    @blur="
                      () => {
                        $refs.payeeName.onFieldBlur();
                      }
                    "
                    read-only
                    style="width: 200px"
                  />
                </a-form-model-item>
              </div>
              <div style="width: 33.3333333%">
                <a-form-model-item
                  label="开户行"
                  prop="bankName"
                  ref="bankName"
                >
                  <a-input
                    v-model="bankInfoParams.bankName"
                    @blur="
                      () => {
                        $refs.bankName.onFieldBlur();
                      }
                    "
                    style="width: 200px"
                  />
                </a-form-model-item>
              </div>
            </div>
            <div style="display: flex">
              <div style="width: 33.333333%">
                <a-form-model-item
                  label="账号"
                  prop="payeeBankNo"
                  ref="payeeBankNo"
                >
                  <a-input
                    v-model="bankInfoParams.payeeBankNo"
                    @blur="
                      () => {
                        $refs.payeeBankNo.onFieldBlur();
                      }
                    "
                    style="width: 200px"
                  />
                </a-form-model-item>
              </div>
              <div style="width: 33.333333%">
                <a-form-model-item
                  label="地址"
                  prop="address"
                  ref="address"
                >
                  <a-input
                    v-model="bankInfoParams.address"
                    @blur="
                      () => {
                        $refs.address.onFieldBlur();
                      }
                    "
                    style="width: 200px"
                  />
                </a-form-model-item>
              </div>
              <div style="width: 33.333333%">
                <a-form-model-item
                  label="电话"
                  prop="phone"
                  ref="phone"
                >
                  <a-input
                    v-model="bankInfoParams.phone"
                    @blur="
                      () => {
                        $refs.phone.onFieldBlur();
                      }
                    "
                    style="width: 200px"
                  />
                </a-form-model-item>
              </div>
            </div>
          </a-form-model>
        </div>

        <div class="email_tab">
          <EmailInfo
            :enterpriseId="enterpriseId"
            style="width: 100%"
          ></EmailInfo>
        </div>
      </div>
    </div>

    <!-- 费用配置 -->

    <div class="fee_config">
      <div class="edit_section">
        <commonTitle title="费用配置" />
        <div class="edit_btn">
          <span class="edit" @click="visibleExamineModalFee = true"
            >添加费用配置</span
          >
        </div>
      </div>

      <div class="info_box">
        <!-- <div style="display: flex; align-items: center; margin: 0 0 10px 0">
          <span>代理商：</span>
          <span v-if="dlsInfo" style="margin-right: 20px">{{
            dlsInfo.userName
          }}</span>
          <span v-else style="margin-right: 20px">--</span>
          <span>结算方式：</span>
          <span v-if="dlsInfo">{{
            dlsInfo.settlementMode === "100402" ? "汇总结算" : "单笔结算"
          }}</span>
          <span v-else style="margin-right: 20px">--</span>
          <a-button
            type="primary"
            @click="freeAthour"
            style="margin-left: 10px"
            v-if="!allowAuthor"
            >审核</a-button
          >
        </div> -->

        <div style="margin-bottom: 30px" v-if="dlsInfo">
          <table class="tbl" cellpadding="0" cellspacing="0">
            <tr style="background: #fafafa">
              <td>类型</td>
              <td>起始费用(元)</td>
              <td>
                结束费用(元)
                <a-tooltip>
                  <template slot="title"> 包含结束费用 </template>
                  <a-button
                    style="color: orange"
                    type="link"
                    shape="circle"
                    icon="info-circle"
                  ></a-button>
                </a-tooltip>
              </td>
              <td>费率(%)</td>
            </tr>
            <tr v-for="(item, index) in checkFeeResult" :key="index">
              <td>阶梯{{ index + 1 }}</td>
              <td>{{ item.ruleBegin }}</td>
              <td>{{ item.ruleEnd }}</td>
              <td>{{ item.feePoint }}</td>
            </tr>
          </table>
        </div>

        <div
          style="margin-bottom: 30px"
          v-if="dlsInfo && dlsInfo.settlementMode === '100401'"
        >
          <table class="tbl" cellpadding="0" cellspacing="0">
            <tr>
              <td>类型</td>
              <td>起始费用(元)</td>
              <td>
                结束费用(元)
                <a-tooltip>
                  <template slot="title"> 包含结束费用 </template>
                  <a-button
                    style="color: orange"
                    type="link"
                    shape="circle"
                    icon="info-circle"
                  ></a-button>
                </a-tooltip>
              </td>
              
            </tr>
            <tr v-for="(item, index) in checkFeeResult" :key="index">
              <td>阶梯{{ index + 1 }}</td>
              <td>{{ item.ruleBegin }}</td>
              <td>{{ item.ruleEnd }}</td>
              
            </tr>
          </table>
        </div>

        <!-- 新增 -->
        <a-modal
          title="新增"
          width="1100px"
          :confirm-loading="confirmLoadingFee"
          destroyOnClose
          :visible="visibleExamineModalFee"
          @ok="handleRechargeModalOkFee"
          @cancel="handleExamineModalCancelFee"
        >
          <table class="tbl" cellpadding="0" cellspacing="0">
            <tr>
              <td>类型</td>
              <td>起始费用(元)</td>
              <td>
                结束费用(元)
                <a-tooltip>
                  <template slot="title"> 包含结束费用 </template>
                  <a-button
                    style="color: orange"
                    type="link"
                    shape="circle"
                    icon="info-circle"
                  ></a-button>
                </a-tooltip>
              </td>
              <td>费率(%)</td>
              
              <td>操作</td>
            </tr>
            <tr v-for="(item, index) in chargeLists" :key="index">
              <td>阶梯{{ index + 1 }}</td>
              <td>
                <a-input
                  class="input"
                  type="number"
                  placeholder="请输入起始费用"
                  v-model="item.ruleBegin"
                />
              </td>
              <td>
                <a-input
                  class="input"
                  type="number"
                  placeholder="请输入结束费用"
                  v-model="item.ruleEnd"
                />
              </td>
              <td>
                <a-input
                  class="input"
                  type="number"
                  placeholder="请输入费用利率"
                  v-model="item.feePoint"
                />
              </td>
              
              <td>
                <div class="operation">
                  <a-button
                    type="dashed"
                    icon="delete"
                    shape="circle"
                    v-show="index === chargeLists.length - 1 && index > 0"
                    @click="delCharge(index, item)"
                    style="margin-right: 10px"
                  ></a-button>
                  <a-button
                    type="primary"
                    icon="plus"
                    shape="circle"
                    v-show="index === chargeLists.length - 1"
                    @click="addCharge(index)"
                  ></a-button>
                </div>
              </td>
            </tr>
          </table>
        </a-modal>

        <!-- 审核 -->
        <a-modal
          title="审核"
          width="1100px"
          :visible="visibleVerfiy"
          :confirm-loading="confirmLoadingVerfiy"
          @ok="handleOkVerfiy"
          @cancel="handleCancelVerfiy"
        >
          <div style="margin-bottom: 30px" v-if="dlsInfo">
            <h4>费用：</h4>
            <table class="tbl" cellpadding="0" cellspacing="0">
              <tr>
                <td>类型</td>
                <td>起始费用(元)</td>
                <td>
                  结束费用(元)
                  <a-tooltip>
                    <template slot="title"> 包含结束费用 </template>
                    <a-button
                      style="color: orange"
                      type="link"
                      shape="circle"
                      icon="info-circle"
                    ></a-button>
                  </a-tooltip>
                </td>
                <td>费率(%)</td>
              </tr>
              <tr v-for="(item, index) in checkAuditFeeResult" :key="index">
                <td>阶梯{{ index + 1 }}</td>
                <td>{{ item.ruleBegin }}</td>
                <td>{{ item.ruleEnd }}</td>
                <td>{{ item.feePoint }}</td>
              </tr>
            </table>
          </div>
          <div
            style="margin-bottom: 30px"
            v-if="dlsInfo && dlsInfo.settlementMode !== '100402'"
          >
            <h4>提成：</h4>
            <table class="tbl" cellpadding="0" cellspacing="0">
              <tr>
                <td>类型</td>
                <td>起始费用(元)</td>
                <td>
                  结束费用(元)
                  <a-tooltip>
                    <template slot="title"> 包含结束费用 </template>
                    <a-button
                      style="color: orange"
                      type="link"
                      shape="circle"
                      icon="info-circle"
                    ></a-button>
                  </a-tooltip>
                </td>
                <td>提成(%)</td>
              </tr>
              <tr v-for="(item, index) in checkAuditFeeResult" :key="index">
                <td>阶梯{{ index + 1 }}</td>
                <td>{{ item.ruleBegin }}</td>
                <td>{{ item.ruleEnd }}</td>
                <td>{{ item.salesPoint }}</td>
              </tr>
            </table>
          </div>
          <a-radio-group name="radioGroup" v-model="selectRadio">
            <a-radio value="102211"> 通过 </a-radio>
            <a-radio value="102231"> 未通过 </a-radio>
          </a-radio-group>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import commonTitle from "../components/commonTitle";
import ContractInfo from "./components/ContractInfo";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import STable from "@/components/table/";
import EmailInfo from "./components/email";
import moment from "moment";

import {
  getEnterpriseBaseInfo,
  getEnterpriseConfig,
  updateEnterpriseConfig,
  getEnterpriseScopeList,
  addEnterpriseBusinessScope,
  getBusinessScopeBox,
  freezeEnterpriseBusinessScope,
  updateCompanyBankInfo,
  updateCompanyIndentifyInfo,
  uploadFile,
  getCompanyIndentifyInfo,
  addCompanyBankInfo,
  getCompanyBankInfo,
  addRebateRules,
  updataRebateRules,
  getEnterpriseInvoiceMailed,
  saveEnterpriseInvoiceMailed,
  taxEnterpriseServiceRuleItemDel,
  taxEnterpriseServiceRuleItemEdit,
  feeAudit,
  getDlsInfo,
  feeAdd,
  feeQuery,
  getAgentBox,
  getUserLoginInfoNowAccount,
  getSysIndustry,
  getSysRegion,
  updateEnterpriseBaseInfo,
  channelFeeAdd,
} from "@/api/api";
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
  name: "raskDetail",
  components: {
    commonTitle,
    ContractInfo,
    STable,
    EmailInfo,
  },
  data() {
    return {
      enterpriseId: "1379686563335159809", //企业id
      taxpayerType: [
        {
          value: "102401",
          label: "一般纳税人",
        },
        {
          value: "102411",
          label: "小规模纳税人",
        },
      ],
      taxpayerTypeText: {
        102401: "一般纳税人",
        102411: "小规模纳税人",
      },
      enterpriseAuthFlag:'edit',
      companyIdentifyParams: {
        enterpriseId: "", // 企业编码
        realName: "", // 企业工商注册名称
        realCode: "", // 企业信用代码
        expirationDate: null, // 过期时间
        remarkDate: null,
        sitePhotos2: "", // 身份证反面
        sitePhotos1: "", // 身份证正面
        enterpriseLicense: "", // 营业执照
      }, // 企业认证参数
      companyIdentifyRules: {
        realName: [
          { required: true, message: "请输入工商注册名称", trigger: "blur" },
        ],
        realCode: [
          { required: true, message: "请输入企业信用代码", trigger: "blur" },
        ],
        remarkDate: [
          { required: true, message: "请输入过期时间", trigger: "change" },
        ],
        enterpriseLicense: [
          { required: true, message: "请上传营业执照", trigger: "change" },
        ],
        sitePhotos1: [
          { required: true, message: "请上传身份证照片", trigger: "change" },
        ],
      },
      url: {
        frontUrl: window._CONFIG["domianURL"] + "/api/tax/common/file/upload",
      }, //上传文件的地址
      headers: { "X-Access-Token": Vue.ls.get(ACCESS_TOKEN) },
      scopeColumns: [
        
        {
          title: "服务名称",
          align: "center",
          dataIndex: "businessScopeName",
        },
        {
          title: "税率",
          align: "center",
          dataIndex: "taxRate",
          customRender: (text) => text * 100 + "%",
        },
        {
          title: "状态",
          align: "center",
          dataIndex: "status",
          customRender: (text) =>
            text === "100201" ? "正常" : text === "100202" ? "停用" : "",
        },
        {
          title: "创建时间",
          align: "center",
          dataIndex: "createTime",
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "action",
          width: "150px",
          scopedSlots: { customRender: "action" },
        },
      ],
      pagination: {},
      addInvoiceRangeModal: false, //添加开票范围模态框状态
      scopeRateList: [], //开票范围
      addScopeForm: this.$form.createForm(this), // 添加开票范围表单
      drawInvoiceInfo: this.$form.createForm(this), //开票信息
      editBaseForm:this.$form.createForm(this),//编辑企业基本信息
      // drwaInvoiceInfoFlag:'edit',
      drwaInvoiceInfoFlag: "edit",
      allowAuthor: false,
      visibleExamineModalFee: false, //添加费用配置模态框状态
      detailInfo: {
        id: "",
        enterpriseName: "",
        status: "",
        enterpriseContacts: "",
        enterprisePhone: "",
        createTime: "",
      },
      bankInfoParams: {
        bankName: "", // 银行名称
        enterpriseId: "", // 企业编码
        payeeBankNo: "", // 银行卡号
        payeeName: "", // 开户人
        subBankName: "", // 开户行
        id: "",
        address:'',
        phone:'',
      },
      rules: {
        enterpriseId: [
          { required: true, message: "请输入企业编号", trigger: "blur" },
        ],
        bankName: [
          { required: true, message: "请输入银行名称", trigger: "blur" },
        ],
        payeeBankNo: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        payeeName: [
          { required: true, message: "请输入开户人", trigger: "blur" },
        ],
        subBankName: [
          { required: true, message: "请输入开户行", trigger: "blur" },
        ],
      },
      rebateParams: {
        rebatesState: undefined,
        rebatesCycle: undefined,
      },
      rebateRules: {
        rebatesState: [
          { required: true, message: "请选择是否返利", trigger: "change" },
        ],
        rebatesCycle: [
          { required: true, message: "请选择返利周期", trigger: "change" },
        ],
      },
      selectRebate: false,
      rebateId: 0,
      chargeId: 0,
      dlsInfo: null,
      // 配置信息
      configForm: this.$form.createForm(this),
      modalType: "detail",
      configLabelCol: {
        lg: { span: 6 },
        md: { span: 8 },
      },
      configWrapperCol: {
        lg: { span: 8 },
        md: { span: 8 },
      },
      sitePhotos2: "", // 身份证反面
      sitePhotos1: "", // 身份证正面
      enterpriseLicense: "", // 营业执照
      timeValue: undefined,
      timeValue1: undefined,
      loadingBusinessLicence: false,
      loadingidCardFront: false,
      loadingidCardOpsite: false,
      //配置信息
      configDetail: {
        account_balance_remind_email: "",
        account_balance_remind_phone: "",
        account_balance_remind_type: "",
        bank_balance_remind_pric: "",
        bill_remind_email: "",
        bind_mail: "",
        bind_phone: "",
        early_warning_remind_email: "",
        quotas_bank: "",
        quotas_wx: "",
        quotas_zfb: "",
        recharge_remind_email: "",
        recharge_remind_phone: "",
        recharge_remind_type: "",
        service_charge_bear: "",
        service_charge_point: "",
        tax_remind_email: "",
        wx_balance_remind_pric: "",
        zfb_balance_remind_pric: "",
      },
      getKpInfo: null, //开票信息
      //配置信息
      checkFeeResult: [],
      checkAuditFeeResult: [],
      chargeLists: [
        {
          feePoint: 0,
          salesPoint: 0,
          ruleBegin: 0,
          ruleEnd: 0,
        },
      ],
      confirmLoadingFee: false, //新增配置信息模态框加载状态
      nowChannelUserId: "",
      selectRadio: "102211", //审核配置信息单选框
      confirmLoadingVerfiy: false, //审核配置信息加载状态
      visibleVerfiy: false, //审核配置信息模态框
      visibleCreateModal:false,//基本信息修改
      editBaseInfoconfirmLoading:false,//基本信息修改加载状态
      selectTheArea:[],//企业地址
      industryLists:[],//行业
      residences:[],//地区省份
      originBaseInfo:{},//最先的基础信息
      validatorRules: {
        enterpriseName: {
          rules: [
            {
              required: true,
              message: "请输入企业名称",
            },
          ],
        },}
    };
  },
  created() {
    console.log("======================");
    console.log(this.$route);
    console.log("======================");
    this.enterpriseId = this.$route.params.id;
    // this.enterpriseId = window.sessionStorage.getItem("enterpriseId");
    this.getEnterpriseBaseInfo();
    this.getEnterpriseConfig();
    this.getRebateRules();
    this.getDlsInfos();
    this.getCompanyIndentifyInfo();
    this.getEnterpriseBankInfo();
    this.getUserLoginInfoNowAccountId();
  },
  mounted() {},
  methods: {
    /**
     * @description: 打开编辑弹窗
     * @param :
     * @return:
     * @Author: HuangCheng
     */
    handleEdit() {
      // console.log(record)
      
      this.handleModalVisible(true, "edit");
      // this.getEnterpriseBaseInfo(this.enterpriseId);
      this.selectTheArea = [
        this.originBaseInfo.enterpriseProvince,
        this.originBaseInfo.enterpriseCity,
        this.originBaseInfo.enterpriseArea,
      ];
      
      getEnterpriseBaseInfo(this.enterpriseId).then((res) => {
        // // console.log(res)
        if (res.success) {
          const result = res.result;
          
          this.editBaseForm.setFieldsValue({
            enterpriseName: result.enterpriseName,
            taxpayerType: result.taxpayerType,
            enterpriseContacts: result.enterpriseContacts,
            enterprisePhone: result.enterprisePhone,
            enterpriseAddress: result.enterpriseAddress,
            enterpriseIndustry:result.enterpriseIndustry,
             residence: [
               this.originBaseInfo.enterpriseProvince + "",
               this.originBaseInfo.enterpriseCity + "",
               this.originBaseInfo.enterpriseArea + "",
             ],
            // enterpriseIndustry: this.mdl.enterpriseIndustry + "",
          });
          // this.uploadList = {
          //   enterpriseLicense: result.enterpriseLicense,
          //   enterpriseCardFront: result.enterpriseCardFront,
          //   enterpriseCardReverse: result.enterpriseCardReverse,
          // };
        } else {
          this.$message.error(res.message);
        }
      });
      
    },
    //获取编辑信息
    handleModalVisible(isVisible, type) {
     
      this.getSysRegion();
      this.visibleCreateModal = isVisible;
      // 获取行业
      getSysIndustry().then((res) => {
        if (res.success) {
          this.industryLists = res.result;
          this.industryLists.forEach((item) => {
            item.id = item.id + "";
          });
        }
      });
    },
    // 获取地区省份
    getSysRegion() {
      getSysRegion().then((res) => {
        if (res.success) {
          this.residences = res.result;
        }
      });
    },
    /**
     * @description: 新增弹窗确定事件
     * @Author: HuangCheng
     */
    handleCreateModalOk() {
      // if (this.modalType === 'detail') { // 详情
      //   this.handleCreateModalCancel();
      //   return;
      // }
      this.editBaseForm.validateFields((err, fieldsValue) => {
        if (err) {
          console.log(err);
          return;
        }
        const description = this.editBaseForm.getFieldsValue();
        console.log(description)
        // const params = Object.assign({}, description, {
        //   enterpriseLicense: this.uploadList.enterpriseLicense,
        //   enterpriseCardFront: this.uploadList.enterpriseCardFront,
        //   enterpriseCardReverse: this.uploadList.enterpriseCardReverse
        // });
        this.editBaseInfoconfirmLoading = true;

        
          // 修改
          // description.sysUserId = this.selectedUserId[1]
          updateEnterpriseBaseInfo(
            Object.assign(
              {
                enterpriseProvince: this.selectTheArea[0],
                enterpriseCity: this.selectTheArea[1],
                enterpriseArea: this.selectTheArea[2],
              },
              description,
              {
                id: this.enterpriseId,
                // userId: window.sessionStorage.getItem('userId'),
              }
            )
          ).then((res) => {
            this.editBaseInfoconfirmLoading = false;
            if (res.success) {
              this.$message.success("修改成功");
              this.handleCreateModalCancel();
              // this.handleToSearchEnterprise();
              this.getEnterpriseBaseInfo();
            } else {
              this.$message.error(res.message);
            }
          });
        
      });
    },
    // 选择地区
      selectArea (value, selectedOptions) {
        // console.log(value, selectedOptions)
        this.selectTheArea = value
      },
    /**
     * @description: 关闭新增弹窗并清空表单数据
     * @Author: HuangCheng
     */
    handleCreateModalCancel() {
      this.editBaseForm.resetFields();
      // this.uploadList = {
      //   enterpriseLicense: "",
      //   enterpriseCardFront: "",
      //   enterpriseCardReverse: "",
      // };
      this.visibleCreateModal = false;
    },
    //查询用户当前登录信息
    getUserLoginInfoNowAccountId() {
      getUserLoginInfoNowAccount().then((res) => {
        if (res.success) {
          this.nowChannelUserId = res.result.userId;
        }
      });
    },
    toEnterpriseManage() {
      window.localStorage.setItem("submenu", "raskManage");
      //   this.$router.push('/rask')
      this.$router.push("/raskManage");
    },
    /**
     * @description: 获取企业详情
     * @Author: HuangCheng
     */
    getEnterpriseBaseInfo() {
      getEnterpriseBaseInfo(this.enterpriseId).then((res) => {
        if (res.success) {
          const result = res.result;
          this.detailInfo = res.result;
          this.originBaseInfo = res.result;
          


          console.log('企业详情', this.detailInfo);
          this.bankInfoParams.payeeName = this.detailInfo.enterpriseContacts;
          this.bankInfoParams.enterpriseId = this.detailInfo.id;
          this.companyIdentifyParams.enterpriseId = this.detailInfo.id;
          this.detailInfo.detailedAddress = this.detailInfo.detailedAddress.replace(/\//g, '')
          


        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取企业认证信息
    async getCompanyIndentifyInfo() {
      await getCompanyIndentifyInfo(this.enterpriseId).then((res) => {
        if (res.success && res.result !== null) {
          this.companyIdentifyParams = res.result;
          this.sitePhotos2 = this.companyIdentifyParams.enterpriseSitePhotos2;
          this.sitePhotos1 = this.companyIdentifyParams.enterpriseSitePhotos1;
          this.enterpriseLicense = this.companyIdentifyParams.enterpriseLicense;
          const expirationDate = new moment();
          expirationDate._d = new Date(
            this.companyIdentifyParams.expirationDate
          );
          this.companyIdentifyParams.remarkDate = expirationDate;
          this.timeValue = this.companyIdentifyParams.expirationDate;
          this.timeValue1 = this.companyIdentifyParams.expirationDate.slice(0,10);
          
        }
      });
      await getCompanyBankInfo(this.enterpriseId).then((res) => {
        // // console.log('企业开票信息',res)
        this.getKpInfo = res.result;
        this.bankInfoParams.payeeName = this.companyIdentifyParams.realName;
        if (res.success && res.result !== null) {
          this.bankInfoParams.bankName = res.result.bankName;
          this.bankInfoParams.payeeBankNo = res.result.bankNo;
          this.bankInfoParams.subBankName = res.result.subBankName;
          this.bankInfoParams.id = res.result.id;
          // this.detailInfo.enterpriseAddress = res.result.address;
          // this.detailInfo.enterprisePhone = res.result.phone;
        }
      });
    },
    // 获取企业信息
    getEnterpriseBankInfo() {
      getCompanyBankInfo(this.enterpriseId).then((res) => {
        // // console.log('企业开票信息',res)
        if (res.success && res.result !== null) {
          this.bankInfoParams.bankName = res.result.bankName;
          this.bankInfoParams.payeeBankNo = res.result.bankNo;
          this.bankInfoParams.subBankName = res.result.subBankName;
          this.bankInfoParams.id = res.result.id;
          this.bankInfoParams.address = res.result.address;
          this.bankInfoParams.phone = res.result.phone;
          // this.detailInfo.enterpriseAddress = res.result.address;
          // this.detailInfo.enterprisePhone = res.result.phone;
        }
      });
    },
    /**
     * @description: 获取企业经营范围列表
     * @param : parameter 查询参数
     * @return: promise
     * @Author: HuangCheng
     */
    loadScopeList(parameter) {
      return getEnterpriseScopeList(
        Object.assign(parameter, {
          enterpriseId: this.enterpriseId,
        })
      ).then((res) => {
        this.pagination = {
          pageSize: res.result.pageSize,
          current: res.result.pageNo,
        };
        return res.result;
      });
    },
    /**
     * @description: 获取企业配置信息
     * @return: void
     * @Author: HuangCheng
     */
    getEnterpriseConfig() {
      getEnterpriseConfig(this.enterpriseId).then((res) => {
        // // console.log('企业配置信息', res)
        if (res.success) {
          this.configDetail = res.result;
          this.configDetail.quotas_bank = Number(this.configDetail.quotas_bank).toFixed(2)
        } else {
          this.$message.error(res.message);
        }
      });
    },

    /**
     * @description: 配置信息按钮点击事件
     * @param :
     * @return:
     * @Author: HuangCheng
     */
    handleConfigSubmit() {
      const description = this.configForm.getFieldsValue();
      if (this.modalType === "detail") {
        this.modalType = "edit";
        const data = this.configDetail;
        setTimeout(() => {
          this.configForm.setFieldsValue({
            remind_type: data.remind_type,
            remind_email: data.remind_email,
            remind_phone: data.remind_phone,
            account_balance_remind_email: data.account_balance_remind_email,
            account_balance_remind_phone: data.account_balance_remind_phone,
            account_balance_remind_type: data.account_balance_remind_type,
            bank_balance_remind_pric: data.bank_balance_remind_pric,
            bill_remind_email: data.bill_remind_email,
            bind_mail: data.bind_mail,
            bind_phone: data.bind_phone,
            early_warning_remind_email: data.early_warning_remind_email,
            quotas_bank: data.quotas_bank,
            quotas_wx: data.quotas_wx,
            quotas_zfb: data.quotas_zfb,
            recharge_remind_email: data.recharge_remind_email,
            recharge_remind_phone: data.recharge_remind_phone,
            recharge_remind_type: data.recharge_remind_type,
            service_charge_bear: data.service_charge_bear,
            service_charge_point: data.service_charge_point,
            tax_remind_email: data.tax_remind_email,
            wx_balance_remind_pric: data.wx_balance_remind_pric,
            zfb_balance_remind_pric: data.zfb_balance_remind_pric,
          });
        }, 0);
        return;
      }
      this.configForm.validateFields((err, values) => {
        if (err) {
          return;
        }
        const description = this.configForm.getFieldsValue();
        description.bind_mail = description.bind_mail.trim();
        //   this.loading = true;
        updateEnterpriseConfig(this.enterpriseId, description).then((res) => {
          // this.loading = false;
          if (res.success) {
            this.modalType = "detail";
            this.getEnterpriseConfig();
            this.$message.success("修改成功");
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    //选择开票范围
    handleChangeSelectCompany(value) {},
    handleSearchSelectCompany(value) {
      setTimeout(
        getBusinessScopeBox({
          businessScope: value,
        }).then((res) => {
          if (res.success) {
            this.scopeRateList = [];
            this.scopeRateList = res.result;
          } else {
            this.$message.error(res.message);
          }
        }),
        1000
      );
    },
    openInvoiceRangeModal() {
      this.addInvoiceRangeModal = true;
      getBusinessScopeBox({
        businessScope: "",
      }).then((res) => {
        if (res.success) {
          this.scopeRateList = res.result;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /**
     * @description: 添加开票范围
     * @return: void
     * @Author: HuangCheng
     */
    handleScopeModalOk() {
      this.addScopeForm.validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }
        const description = this.addScopeForm.getFieldsValue();
        // description.businessScopeId = description.businessScopeId.join(',');
        // // console.log(description)
        addEnterpriseBusinessScope(
          Object.assign(description, {
            enterpriseId: this.enterpriseId,
            taxRate: 0.06,
            ...description,
          })
        ).then((res) => {
          if (res.success) {
            this.handleToSearchScope();
            this.$message.success("添加成功");
            this.addScopeForm.resetFields();
            this.addInvoiceRangeModal = false;
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    //开票范围取消
    handleScopeModalCancel() {
      this.addInvoiceRangeModal = false;
      this.addScopeForm.resetFields();
    },
    /**
     * @description: 查询企业开票范围
     * @Author: HuangCheng
     */
    handleToSearchScope() {
      this.$refs.table.refresh(true);
    },
    /**
     * @description: 冻结
     * @param : record 当前行数据
     * @return: void
     * @Author: HuangCheng
     */
    freeStaff(record) {
      const that = this;
      this.$confirm({
        title: `冻结`,
        content: (h) => (
          <div>
            确定冻结<span style="color:red;">“{record.businessScopeName}”</span>
            ?
          </div>
        ),
        onOk() {
          freezeEnterpriseBusinessScope({
            id: record.id,
            status: "100202",
          }).then((res) => {
            if (res.success) {
              that.$message.success(`“${record.businessScopeName}”已冻结`);
              that.handleToSearchScope();
            } else {
              that.$message.error(res.message);
            }
          });
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    },
    // 解冻
    returnOk(record) {
      const that = this;
      this.$confirm({
        title: `冻结`,
        content: (h) => (
          <div>
            确定解冻<span style="color:red;">“{record.businessScopeName}”</span>
            ?
          </div>
        ),
        onOk() {
          return new Promise((resolve, reject) => {
            freezeEnterpriseBusinessScope({
              id: record.id,
              status: "100201",
            }).then((res) => {
              if (res.success) {
                that.$message.success(`“${record.businessScopeName}”已解冻`);
                that.handleToSearchScope();
                resolve();
              } else {
                that.$message.error(res.message);
                reject();
              }
            });
          });
        },
        onCancel() {
          // console.log('Cancel');
        },
        class: "test",
      });
    },
    // 获取企业费用配置信息
    getRebateRules() {
      feeQuery({ enterpriseId: this.enterpriseId, status: "102211" }).then(
        (res) => {
          if (res.success) {
            if (res.result != null) {
              this.checkFeeResult = res.result;
              this.checkFeeResult.forEach((item) => {
                item.salesPoint = (item.salesPoint * 100).toFixed(4);
                item.feePoint = (item.feePoint * 100).toFixed(2);
              });
            }
          }
        }
      );
      feeQuery({ enterpriseId: this.enterpriseId, status: "102201" }).then(
        (res) => {
          if (res.success && res.result) {
            this.allowAuthor = false;
            this.checkAuditFeeResult = res.result;
            this.checkAuditFeeResult.forEach((item) => {
              item.salesPoint = (item.salesPoint * 100).toFixed(4);
              item.feePoint = (item.feePoint * 100).toFixed(2);
            });
          } else {
            this.allowAuthor = true;
          }
        }
      );
    },
    // 获取代理商信息
    getDlsInfos() {
      getDlsInfo(this.enterpriseId).then((res) => {
        if (res.success) {
          this.dlsInfo = res.result;
          this.selectDls = res.result.userId;
          this.allowInput =
            this.dlsInfo.settlementMode === "100402" ? true : false;
        }
      });
    },
    /**
     * 提交企业认证信息
     */
    handleSubmitCompanyIdentify(e) {
      this.$refs.companyIdentifyRule.validate((valid) => {
        if (valid) {
          this.companyIdentifyParams.enterpriseId = this.detailInfo.id;
          // 处理时间
          let time = new Date(
            this.companyIdentifyParams.remarkDate._d
          ).toJSON();
          this.companyIdentifyParams.expirationDate = new Date(
            +new Date(time) + 8 * 3600 * 1000
          )
            .toISOString()
            .replace(/T/g, " ")
            .replace(/\.[\d]{3}Z/, "");
          this.companyIdentifyParams.expirationDate =
            this.timeValue + " 00:00:00";
            // this.timeValue ;
          updateCompanyIndentifyInfo(this.companyIdentifyParams).then((res) => {
            if (res.success) {
              this.$message.success("信息提交成功");
              this.enterpriseAuthFlag = 'edit';
              this.getCompanyIndentifyInfo();
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          // // console.log('error submit!!');
          return false;
        }
      });
    },
    //企业过期时间
    changeTime(value, mode) {
      this.timeValue = mode;
      this.companyIdentifyParams.remarkDate = value;
    },
    //文件上传钩子
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("上传照片格式不正确!");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传照片大小不能超过2MB!");
        return false;
      }
      return isJpgOrPng && isLt2M;
    },
    /**
     * 上传营业执照
     */
    handleChange(info, type) {
      if (info.fileList.length > 1) {
        info.fileList.shift();
      }
      // // console.log('info>>', info)
      // // console.log('type>>', type)
      if (info.file.status === "uploading") {
        switch (type) {
          case "enterpriseLicense":
            this.loadingBusinessLicence = true;
            break;
          case "sitePhotos1":
            this.loadingidCardFront = true;
            break;
          case "sitePhotos2":
            this.loadingidCardOpsite = true;
            break;
        }
        return;
      }
      if (
        info.file.status === "done" &&
        (info.file.type === "image/jpeg" ||
          info.file.type === "image/png" ||
          info.file.type === "image/jpg")
      ) {
        getBase64(info.file.originFileObj, (imageUrl) => {
          let formdata = new FormData();
          switch (type) {
            case "enterpriseLicense":
              this.enterpriseLicense = imageUrl;
              formdata.append("file", info.fileList[0].originFileObj);
              formdata.append("fileType", "102111");
              uploadFile(formdata).then((res) => {
                this.companyIdentifyParams.enterpriseLicense =
                  "https://oss.zfycloud.com" + "/" + res.result;
              });
              this.loadingBusinessLicence = false;
              break;
            case "sitePhotos1":
              this.sitePhotos1 = imageUrl;
              formdata.append("file", info.fileList[0].originFileObj);
              formdata.append("fileType", "102114");
              uploadFile(formdata).then((res) => {
                // // console.log('enterpriseLicense>>', res)
                this.companyIdentifyParams.sitePhotos1 =
                  "https://oss.zfycloud.com" + "/" + res.result;
              });
              this.loadingidCardFront = false;
              break;
            case "sitePhotos2":
              this.sitePhotos2 = imageUrl;
              formdata.append("file", info.fileList[0].originFileObj);
              formdata.append("fileType", "102115");
              uploadFile(formdata).then((res) => {
                // // console.log('enterpriseLicense>>', res)
                this.companyIdentifyParams.sitePhotos2 =
                  "https://oss.zfycloud.com" + "/" + res.result;
              });
              this.loadingidCardOpsite = false;
              break;
          }
        });
      }
    },
    //保存开票信息
    saveDrawInvoiceInfo() {
      this.$refs.bankInfoRule.validate((valid) => {
        if (valid) {
          if (this.getKpInfo !== null) {
            let params = {
              address: this.bankInfoParams.address,
              bankName: this.bankInfoParams.bankName,
              bankNo: this.bankInfoParams.payeeBankNo,
              creditCode: this.companyIdentifyParams.realCode,
              name: this.bankInfoParams.payeeName,
              phone: this.bankInfoParams.phone,
              id: this.bankInfoParams.id,
            };
            updateCompanyBankInfo(params).then((res) => {
              if (res.success) {
                this.$message.success("信息提交成功");
                this.drwaInvoiceInfoFlag = "edit";
                this.getEnterpriseBankInfo();
              } else {
                this.$message.error("信息提交失败");
              }
            });
          } else {
            let params = {
              enterpriseId: this.bankInfoParams.enterpriseId,
              address: this.bankInfoParams.address,
              bankName: this.bankInfoParams.bankName,
              bankNo: this.bankInfoParams.payeeBankNo,
              creditCode: this.companyIdentifyParams.realCode,
              name: this.bankInfoParams.payeeName,
              phone: this.bankInfoParams.phone,
            };
            addCompanyBankInfo(params).then((res) => {
              if (res.success) {
                this.$message.success("信息提交成功");
                this.drwaInvoiceInfoFlag = "edit";
                this.getEnterpriseBankInfo();
              } else {
                this.$message.error("信息提交失败");
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    handleExamineModalCancelFee() {
      this.visibleExamineModalFee = false;
    },
    //新增费用配置
    handleRechargeModalOkFee() {
      // if (this.selectDls === '') {
      //   this.$message.error('请选择代理商')
      //   return
      // }
      let params = {
        enterpriseId: this.detailInfo.id,
        salesUserId: this.nowChannelUserId,
        configs: JSON.parse(JSON.stringify(this.chargeLists)),
      };
      for (let i = 0; i < this.chargeLists.length; i++) {
        if (
          Number(this.chargeLists[i].ruleBegin) >
          Number(this.chargeLists[i].ruleEnd)
        ) {
          this.isAddCharge = false;
          this.$message.error(`费用范围第${i + 1}条数据结束值必须大于起始值`);
          break;
        } else if (this.chargeLists[i].feePoint < 0) {
          this.isAddCharge = false;
          this.$message.error(`费用范围第${i + 1}条数据费用利率不能为负数`);
          break;
        } else if (
          this.chargeLists[i].ruleBegin === "" ||
          this.chargeLists[i].ruleEnd === "" ||
          this.chargeLists[i].feePoint === ""
        ) {
          this.isNullCharge = false;
          this.$message.error(`费用范围第${i + 1}条数据不能为空`);
        } else if (Number(this.chargeLists[i].ruleEnd) === 0) {
          this.isNullCharge = false;
          this.$message.error(`费用范围第${i + 1}条数据不能为零`);
          break;
        } else {
          this.isAddCharge = true;
          this.isNullCharge = true;
        }
      }
      if (!this.isAddCharge || !this.isNullCharge) {
        return;
      }
      this.confirmLoadingFee = true;
      params.configs.forEach((item) => {
        item.ruleBegin = Number(item.ruleBegin);
        item.ruleEnd = Number(item.ruleEnd);
        item.feePoint = item.feePoint;
        item.salesPoint = item.salesPoint;
      });
      // console.log('---?',params);
      
      channelFeeAdd(params).then((res) => {
        this.confirmLoadingFee = false;
        if (res.success) {
          this.$message.success(res.message);
          this.getRebateRules();
          this.getDlsInfos();
          this.handleExamineModalCancelFee();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 费用范围审核
    freeAthour() {
      this.getRebateRules();
      this.visibleVerfiy = true;
    },
    handleOkVerfiy() {
      this.confirmLoadingVerfiy = true;
      let params = {
        enterpriseId: this.detailInfo.id,
        auditStatus: this.selectRadio,
      };
      this.chargeLists.forEach((item) => {
        if (item.id && item.id !== "") {
          params.ids.push(item.id);
        }
      });
      feeAudit(params).then((res) => {
        this.confirmLoadingVerfiy = false;
        if (res.success) {
          this.$message.success(res.message);
          this.getRebateRules();
          this.getDlsInfos();
          this.handleCancelVerfiy();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //审核模态框取消事件
    handleCancelVerfiy() {
      this.visibleVerfiy = false;
    },
    
    // 新增费用范围
      addCharge(index) {
        for (let i = 0; i < this.chargeLists.length; i++) {
          if (Number(this.chargeLists[i].ruleBegin) >= Number(this.chargeLists[i].ruleEnd)) {
            this.isAddCharge = false
            this.isNullCharge = false
            this.$message.error(`费用范围第${i+1}条数据结束值必须大于起始值`);
            break
          } else if (this.chargeLists[i].feePoint < 0) {
            this.isAddCharge = false
            this.isNullCharge = false
            this.$message.error(`费用范围第${i+1}条数据费用利率不能为负数`)
            break
          } else if (this.chargeLists[i].ruleBegin === '' || this.chargeLists[i].ruleEnd === '' || this.chargeLists[i].feePoint === '') {
            this.isNullCharge = false
            this.isAddCharge = false
            this.$message.error(`费用范围第${i+1}条数据不能为空`)
            break
          } else {
            this.isAddCharge = true
            this.isNullCharge = true
          }
        }
        if (this.isAddCharge && this.isNullCharge) {
          this.chargeLists.push({
            ruleBegin: this.chargeLists[index].ruleEnd,
            ruleEnd: '',
            feePoint: '',
            salesPoint: 0,
          })
          this.isAddCharge = false
          this.isNullCharge = false
        }
      },
      // 删除费用范围
      delCharge(index,item) {
        if(this.chargeLists.length > 1) {
          if (!item.id) {
            this.chargeLists.splice(index, 1)
            return
          }
          let that = this
          this.$confirm({
            title: '确定删除？',
            onOk() {
              return new Promise((resolve, reject) => {
                taxEnterpriseServiceRuleItemDel({id:item.id}).then((res) => {
                  if (res.success) {
                    that.$message.success(res.message);
                    that.getRebateRules()
                    resolve();
                  } else {
                    that.$message.error(res.message);
                    reject();
                  }
                });
              }).catch(() => console.log('Oops errors!'));
            },
            onCancel() {
              // console.log('Cancel');
            },
          });
        }
      },
       // 校验手机号
      validatePhone (rule, value, callback) {
        const regex = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
        if (!regex.test(value)) {
          callback('手机号格式不正确')
        }
        callback()
      },
  },
};
</script>

<style lang="less">
.picture-common {
  width: 100px;
}
.tbl {
  // width: 1000px;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  border-collapse: separate;
  > tr {
    > td {
      border-right: 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;
      padding: 10px;
      &:last-child {
        border-right: 0;
      }
    }
    &:first-child {
      td:first-child {
        border-top: 0;
        border-left: 0;
      }
      td:last-child {
        border-top: 0;
        border-right: 0;
      }
    }
    &:last-child {
      td {
        border-bottom: 0;
      }
      td:first-child {
        border-left: 0;
      }
      td:last-child {
        border-right: 0;
      }
    }
  }
}
.upload_box {
  display: flex;
}
.detail_config {
  padding-bottom: 50px;
  .bread {
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #dedede;
    box-sizing: border-box;
    padding-left: 35px;
    margin-bottom: 30px;
    .txt_e {
      color: #347cf5;
      font-size: 14px;
      padding-right: 18px;
      cursor: pointer;
    }
    .txt_d {
      color: #333333;
      font-size: 14px;
      padding-left: 15px;
    }
  }
  .basic_info {
    padding-left: 38px;
    padding-right: 80px;
    margin-bottom: 50px;
    .edit_section {
      display: flex;
      justify-content: space-between;
      .edit_btn {
        .edit {
          color: #347cf5;
          font-size: 14px;
        }
      }
    }
    .info_box {
      padding-left: 18px;
      .row {
        display: flex;
        .col {
          width: 33.33333%;
          font-size: 14px;
          .type {
            color: #737373;
          }
          .desc {
            color: #262626;
          }
        }
      }
      .mar_bott_18 {
        margin-bottom: 18px;
      }
    }
  }
  .contract_info {
    padding-left: 38px;
    padding-right: 80px;
    margin-bottom: 50px;
    .edit_section {
      display: flex;
      justify-content: space-between;
      .edit_btn {
        .edit {
          color: #347cf5;
          font-size: 14px;
        }
      }
    }
    .info_box {
      padding-left: 18px;
    }
  }
  .enterprise_auth {
    padding-left: 38px;
    padding-right: 80px;
    margin-bottom: 50px;
    .edit_section {
      display: flex;
      justify-content: space-between;
      .edit_btn {
        .edit {
          color: #347cf5;
          font-size: 14px;
        }
      }
    }
    .info_box {
      padding-left: 18px;
    }
  }
  .invoice_range {
    padding-left: 38px;
    padding-right: 80px;
    margin-bottom: 50px;
    .edit_section {
      display: flex;
      justify-content: space-between;
      .edit_btn {
        .edit {
          color: #347cf5;
          font-size: 14px;
        }
      }
    }
    .info_box {
      padding-left: 18px;
    }
  }
  .invoice_info {
    padding-left: 38px;
    padding-right: 80px;
    margin-bottom: 50px;
    .edit_section {
      display: flex;
      justify-content: space-between;
      .edit_btn {
        .edit {
          color: #347cf5;
          font-size: 14px;
        }
      }
    }
    .info_box {
      padding-left: 18px;
      .row {
        display: flex;
        .col {
          width: 33.333%;
          font-size: 14px;
          .type {
            color: #737373;
          }
          .desc {
            color: #262626;
          }
        }
      }
      .mar_bott_18 {
        margin-bottom: 18px;
      }
    }
  }
  .fee_config {
    padding-left: 38px;
    padding-right: 80px;
    margin-bottom: 50px;
    .edit_section {
      display: flex;
      justify-content: space-between;
      .edit_btn {
        .edit {
          color: #347cf5;
          font-size: 14px;
        }
      }
    }
    .info_box {
      padding-left: 18px;
    }
  }
  .config_info {
    padding-left: 38px;
    padding-right: 80px;
    margin-bottom: 50px;
    .edit_section {
      display: flex;
      justify-content: space-between;
      .edit_btn {
        .edit {
          color: #347cf5;
          font-size: 14px;
        }
      }
    }
    .info_box {
      padding-left: 18px;
    }
  }
}
</style>